import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";

export interface CookiePolicyPageModel {
  title: string;
  seo: string;
  _rawDescription: any;
}

export const query = graphql`
  query CookiePolicyQuery {
    site: sanityCookiePolicyPage {
      _rawDescription
      seo
      title
    }
  }
`;

const CookiePolicyPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: CookiePolicyPageModel = (data || {}).site;

  if (!site) {
    return (
      <Layout>
        <Container role="main">
          <h1>Something went wrong!</h1>
          <p>Sorry, something went wrong there. try to reload the page</p>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.seo} />
      <Container role="main">
        <h1>{site.title}</h1>
        {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
      </Container>
    </Layout>
  );
};

export default CookiePolicyPage;
